<template>
  <div class="edit_item type_card">
    <div class="item_head">
      <button
        class="thumb_area thumb_btn"
        aria-hidden="true"
        @click="goArtworkDetail(itemData.artworkId, itemData.editionId)"
        @focus="profileFocused = true"
        @blur="profileFocused = false"
      >
        <div>
          <img
            v-if="source.small"
            :src="source.small"
            :alt="itemData.title ? itemData.title : ''"
            :width="480"
          />
          <img
            v-else
            :src="require('@/assets/img/img_illust_nodata.png')"
            alt="nodata"
          />
        </div>
      </button>
      <!-- PUB : Origin code-->
      <!-- <div v-if="itemData.mimeType === 'video/mp4'" class="ic_area type_video">
        <IcVideo />
      </div> -->
      <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
      <!-- WIP : 아이콘 분기-->
      <div class="ic_area type_blockchains">
        <IcHedera v-if="itemData.bcNwTp == '10'" />
        <IcEth v-else />
      </div>
      <button
        class="btn type_ic btn_like"
        type="button"
        :class="{
          is_active: likeStatus,
        }"
        @click="likeThis(itemData, itemIndex)"
      >
        <IcLike
          v-if="!likeStatus"
          icon-color="#fff"
          ref="icLike"
          :status="!likeStatus"
        />
        <IcLike v-else icon-color="#ff521d" ref="icLike" />
      </button>
    </div>
    <div class="item_body" :class="{ activate: profileFocused }">
      <div
        @click="goArtworkDetail(itemData.artworkId, itemData.editionId)"
        class="link"
        :aria-describedby="itemData.artworkId"
        :title="itemData.artworkId"
      ></div>
      <div class="basic_info_group">
        <button
          class="profile_info is_art"
          @click="goArtistProfile()"
          @focus="profileFocused = true"
          @blur="profileFocused = false"
        >
          <span
            v-if="
              itemData.artistInfo.avatarInfo &&
              itemData.artistInfo.avatarInfo.length
            "
            class="profile"
            :style="`background-image: url(${itemData.artistInfo.avatarInfo[0].resUrl})`"
          ></span>
          <IcProfile v-else class="profile" />
          <IcRing class="ic_ring" :width="32" :height="32" />
          <span class="name">{{ itemData.artistInfo.name }}</span>
        </button>
        <div class="ttl_area">
          <h3 :id="itemData.artworkId" class="ttl">{{ itemData.title }}</h3>
          <p class="edit">
            {{
              itemData.totalEdition > 1
                ? `${itemData.totalEdition} Editions`
                : `${itemData.totalEdition} Edition`
            }}
          </p>
        </div>
      </div>
      <div class="state_info clearFix">
        <div class="price_area">
          <template v-if="price === null">
            <p v-if="!price" class="price chip">Loading...</p>
          </template>
          <template v-else>
            <p class="price chip">Not for Sale</p>
          </template>
          <!-- <template v-else>
            <template>
              <p v-if="!price || price === 0" class="price chip">
                Not for Sale
              </p>
            </template>
            <template v-if="price && price !== 0">
              <p class="type">Floor Price</p>
              <p class="price"> -->
          <!-- PUB : 블록체인 금액표기 수정 -->
          <!-- WIP : 금액표기 분기-->
          <!-- <b v-if="itemData.bcNwTp == '10'">{{ price | setNum }} USDC</b>
                <b v-else
                  >{{ price }}<span class="unit">Ξ</span
                  ><span class="dollars"
                    >({{ priceToEthUsd | crtpyAbbreviate }})</span
                  ></b
                >
              </p>
            </template>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcLike from '@/components/ic/IcLike';
// import IcVideo from '@/components/ic/IcVideo';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'MarketItem',
  components: {
    IcRing,
    IcLike,
    IcProfile,
    IcEth,
    IcHedera,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      source: {},
      likeStatus: false,
      profileFocused: false,
      editionItems: [],
      price: null,
      priceToEthUsd: 0,
    };
  },

  async mounted() {
    this.source = this.setThumbnailImg(this.itemData);
    if (this.itemData.favorites === 'Y') {
      this.likeStatus = true;
    }

    await this.getMarketplaceArtworkOrEdition(this.itemData);
  },
  methods: {
    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.itemData.artistMbrNo || this.itemData.artistInfo.mbrNo,
        },
      });
    },

    // TIP: marketplace Detail SET
    goArtworkDetail(artworkId, editionId) {
      this.$router.push({
        name: 'marketplaceDetail',
        query: {
          artworkId: artworkId,
          editionId: editionId,
        },
      });
    },
    likeThis(item, likeIdx) {
      let divLike = document.querySelectorAll('.btn_like')[likeIdx];
      console.log(divLike);
      if (!this.$refs.icLike.loginCheck(divLike)) {
        return;
      } else {
        let favoritesYn = this.likeStatus === true ? 'N' : 'Y';

        if (item.editionId === 0) {
          let params = {
            artworkId: item.artworkId,
            favoritesYn,
          };

          this.$refs.icLike.likeArtwork(params);
        } else {
          let params = {
            artworkId: item.artworkId,
            editionId: item.editionId,
            favoritesYn,
          };
          this.$refs.icLike.likeEdition(params);
          this.$refs.icLike.likeArtwork(params);
        }

        this.likeStatus = !this.likeStatus;
      }
    },
    async getMarketplaceArtworkOrEdition(item) {
      let artworkDetailResult;
      let query = {
        artworkId: item.artworkId,
      };
      let totalCount;

      if (item.editionId != 0) {
        //싱글에디션일경우
        query = {
          ...query,
          editionId: item.editionId,
        };
        artworkDetailResult = await this.api.getEditionDetail(query);
      } else {
        // 멀티에디션일 경우
        artworkDetailResult = await this.api.getArtworkDetail(query);
      }
      if (artworkDetailResult.code === 0) {
        totalCount = artworkDetailResult.editionInfos.totalCount;
      }

      let params = { artworkId: item.artworkId, page: 1, cnt: totalCount };
      await this.api.getArtworkEditionList(params).then(res => {
        if (res.code === 0) {
          this.editionItems = res.editionItems;
          this.editionItems.find(item => {
            return item.salesMethod !== 1;
          });
        }
      });
      let minSalePrice = 0;
      let minSalePriceToEthUsd = 0;

      if (this.editionItems.length > 1) {
        this.editionItems
          .filter(s => s.salesMethod !== 1 && s.salesMethod !== 'none')
          .forEach(i => {
            if (item.artworkId === i.artworkId) {
              if (minSalePrice === 0) {
                minSalePrice = i.price;
                minSalePriceToEthUsd = i.priceToEthUsd;
              } else {
                if (i.price < minSalePrice) {
                  minSalePrice = i.price;
                  minSalePriceToEthUsd = i.priceToEthUsd;
                }
              }
            }
          });
        if (minSalePrice === 0) {
          this.price = 0;
          this.priceToEthUsd = 0;
        } else {
          this.price = minSalePrice;
          this.priceToEthUsd = minSalePriceToEthUsd;
        }
      } else {
        if (
          this.editionItems[0].salesMethod !== 1 &&
          this.editionItems[0].salesMethod !== 'none'
        ) {
          this.price = this.editionItems[0].price;
          this.priceToEthUsd = this.editionItems[0].priceToEthUsd;
        } else {
          this.price = 0;
          this.priceToEthUsd = 0;
        }
      }
    },
  },
};
</script>

<style scoped>
.item_head,
.item_body,
.profile_info {
  cursor: pointer;
}
.ttl_area .ttl {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.thumb_btn {
  width: 100%;
}
</style>
