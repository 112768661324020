<template>
  <div
    class="modal_wrap"
    :class="{ is_show: bannerMobileModal }"
    style="padding-top: 50px"
  >
    <div class="modal" role="dialog" style="">
      <div class="modal_head">
        <h3 class="modal_ttl">{{ subject }}</h3>
      </div>
      <div class="modal_body">
        <div class="has_scroll_yy">
          <p
            class="desc"
            style="
              text-align: left;
              line-height: 30px;
              font-size: 1.8rem;
              height: 40rem;
            "
          >
            <!-- {{ content }} -->
            <span style="font-weight: bold">Dear LG Art Lab Community,</span
            ><br />
            We want to share an important update regarding LG Art Lab.<br /><br />

            <span style="font-weight: bold; color: #ff521f"
              >After careful consideration, we have made the difficult
              decision<br />
              to close the platform. </span
            ><br />
            As the NFT space continues to evolve, we believe it is the right
            time<br />
            to shift our focus and explore new opportunities. <br /><br />
            Since launching, LG Art Lab has been dedicated to bridging the world
            of <br />digital art with cutting-edge display technology, bringing
            NFT collections to<br />
            a wider audience through our innovative platform. We have
            collaborated with visionary artists, introduced groundbreaking NFT
            drops, and provided<br />
            a seamless experience for collectors to enjoy digital art on<br />
            LG’s premium screens. Our commitment to fostering creativity<br />
            and innovation in the NFT space has been at the heart of
            everything<br />
            we’ve done. <br /><br />
            LG Art Lab will officially close on
            <span style="font-weight: bold">June 17, 2025.</span><br />
            To ensure a smooth transition, please take note of the following:<br />
            &nbsp;&nbsp;&nbsp;&nbsp; - Final date for transactions:
            <span style="font-weight: bold">March 10, 2025</span><br />
            &nbsp;&nbsp;&nbsp;&nbsp; - How to withdraw and transfer asset (there
            are two ways): <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (1) LG Art Lab Team
            will automatically transfer the assets<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            that are currently in LG Art Lab Marketplace for re-sell to<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            collectors own wallets by
            <span style="font-weight: bold">April 30, 2025.</span><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) Artists and
            collectors can manually transfer artworks that are<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            reside in LG Art Lab Marketplace for re-sell to their own wallets<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            or retract for re-selling in LG Art Lab Marketplace. <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Please note that Etherium minted NFTs can be re-sold in<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            other platforms (e.g. Opensea)<br />
            <img :src="require('@/assets/img/popup.png')" />
            <br /><br />
            &nbsp;&nbsp;&nbsp;&nbsp; - Officially closing date:
            <span style="font-weight: bold">June 17, 2025</span><br />
            &nbsp;&nbsp;&nbsp;&nbsp; - Customer support: available until
            <span style="font-weight: bold">July 18, 2025</span><br />
            We are incredibly grateful for the artists, collectors, and
            enthusiasts<br />
            who have been part of this journey. Your support and creativity have
            made <br />LG Art Lab a truly special place, and we sincerely
            appreciate the trust<br />
            you’ve placed in us. While this chapter is coming to an end, our
            passion<br />
            for digital art and innovation remains strong, and we look forward
            to seeing <br />how the NFT space continues to grow and evolve.<br /><br />
            Thank you for being part of LG Art Lab<br />
            With appreciation, <br />
            LG Art Lab Team<br />
          </p>
        </div>
      </div>

      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m strong" @click="close">
            {{ btnTxt }}
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';

export default {
  name: 'noticeModal',
  components: { IcCloseM },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    bannerMobileModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    bannerMobileModal: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  computed: {
    subject() {
      return '[Important Update] LG Art Lab is Closing';
    },
    content() {
      return `Hello CES members.\nWe appreciate your journey to our platform.\nThe actual drop will start in February.\nPlease set a reminder from your mobile\nto purchase when the drop starts.
`;
    },
    btnTxt() {
      return 'Got it';
    },
  },
  data() {
    return {};
  },
  methods: {
    open() {
      this.show = true;
      this.$root.$emit('setScrollFixed', true);
    },
    close() {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('closeMobileEnd');
    },
  },
};
</script>

<style scoped>
/*
.modal_wrap.is_home {
  background: none !important;
}
*/
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
.desc {
  white-space: pre-line;
  text-align: center;
}
.modal_body .has_scroll_y {
  max-height: 52rem;
}
.modal_body .has_scroll_yy {
  max-height: 50rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal_body .has_scroll_yy::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  background-color: #222;
  /* fff 222 */
}
.modal_body .has_scroll_yy::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.modal_body .has_scroll_yy::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}
</style>
