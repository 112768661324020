<template>
  <main id="contents" class="my_list_page other_page">
    <h1 class="page_ttl is_blind">other_page</h1>
    <div class="set_inner">
      <section class="other_sec">
        <div class="other_sec_head">
          <div class="profile_group">
            <div class="profile_info">
              <span
                v-if="avatarUrl !== '' && avatarUrl"
                class="profile"
                :style="`background-image: url('${avatarUrl}')`"
              ></span>
              <span v-else class="profile none">
                <IcProfile class="ic_profile" width="113" height="113" />
              </span>
              <h2 class="ttl">{{ userInfo.name }}</h2>
            </div>
          </div>
          <div class="follow_group">
            <ul class="follow_area">
              <li class="following">
                <button
                  @click="openModal('FollowModal', false)"
                  ref="following"
                  role="status"
                >
                  {{ +userInfo.following | numberFormatter(2) }}
                  <span>Following</span>
                </button>
              </li>
              <li class="followers">
                <button
                  @click="openModal('FollowModal', true)"
                  ref="followers"
                  role="status"
                >
                  {{ +userInfo.followers | numberFormatter(2) }}
                  <span>Followers</span>
                </button>
              </li>
            </ul>
            <div class="btn_area" v-if="mbrNo !== null && myMbrNo !== mbrNo">
              <button
                @click="follow()"
                ref="follow"
                class="btn basic w_m h_l"
                :class="{ is_active: userInfo.followYn === 'Y' }"
              >
                <IcMember
                  v-if="userInfo.followYn === 'N'"
                  stroke-color="#000000"
                />
                <IcCheck v-if="userInfo.followYn === 'Y'" />
                {{ userInfo.followYn === 'Y' ? 'Following' : 'Follow' }}
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="ownedEditions && ownedEditions.length"
          class="other_sec_body"
        >
          <div class="data_length_area">
            <h2 class="ttl">
              <em>{{ ownedEditions.length }}</em> Owned
            </h2>
          </div>
          <ul class="grid grid_15 ib edit_list">
            <li
              v-for="item in ownedEditions"
              :key="item.id"
              class="col col_3 edit_node"
            >
              <ItemCardEdit
                :itemData="item"
                :userType="userType"
                :viewType="'other'"
                :mbrNo="myMbrNo"
              />
            </li>
          </ul>
          <InfinityScroll @scroll="loadMore" />
        </div>
        <div v-else class="other_sec_body">
          <div class="data_length_area">
            <h2 class="ttl"><em>0</em> Owned</h2>
          </div>
          <div class="guide_ui no_data">
            <div class="img_area">
              <picture>
                <source
                  media="(max-width: 600px)"
                  type="image/png"
                  :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                />
                <img
                  :src="require('@/assets/img/img_illust_nodata.png')"
                  alt="img_illust_nodata"
                />
              </picture>
            </div>
            <p>{{ userInfo.name }} doesn’t have own artworks yet!!</p>
            <button
              class="btn w_xl h_l strong"
              style="margin-top: 20px"
              @click="goMarketPlaceItemList()"
            >
              Go to Marketplace
            </button>
          </div>
        </div>
      </section>
    </div>
    <FollowList
      id="followList"
      ref="FollowModal"
      :mbrNo="mbrNo"
      @follow="handleFollow"
      @onSetCloseFocus="onSetCloseFocus"
      :key="componentKey"
    />
  </main>
</template>

<script>
import IcCheck from '@/components/ic/IcCheck';
import IcMember from '@/components/ic/IcMember';
import IcProfile from '@/components/ic/IcProfile';
import FollowList from '@/components/mylist/FollowList';
import ItemCardEdit from '@/components/mylist/ItemCardEdit';
import InfinityScroll from '@/components/common/InfinityScroll';
import { mapGetters } from 'vuex';
export default {
  name: 'OtherProfileView',
  components: {
    IcCheck,
    IcMember,
    IcProfile,
    FollowList,
    ItemCardEdit,
    InfinityScroll,
  },
  data() {
    return {
      followBtn: true,
      userInfo: [],
      ownedEditions: [],
      avatarUrl: '',
      mbrNo: null,
      componentKey: 0,
      userType: '1',
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      nftList: [],
      pageNumber: 1,
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      myMbrNo: 'userStore/mbrNo',
    }),
  },
  created() {
    this.getUserProfile();
  },
  methods: {
    handleFollow(item) {
      this.componentKey++;
      this.getUserProfile(item);
    },
    getCallNftList(item) {
      this.mbrNo = item?.mbrNo || this.$route.params.mbrNo;
      if (!this.mbrNo) this.$router.push('/unknown');

      const data = {
        mbrNo: this.mbrNo,
        pageNumber: this.pageNumber,
        cursor: null,
      };
      this.api.getTaasCallNftList(data).then(res => {
        if (res.code === 0) {
          this.nftList.push(...res.nftList);
          this.pageNumber++;
          return this.nftList;
        } else {
          this.$router.push('/network');
        }
      });
    },
    getUserProfile(item) {
      const nftList = this.getCallNftList();

      this.mbrNo = item?.mbrNo || this.$route.params.mbrNo;
      if (!this.mbrNo) this.$router.push('/unknown');
      const data = {
        mbrNo: this.mbrNo,
        nftList: nftList,
      };
      if (item?.userType === 1 || !item) {
        this.api.getOtherUserProfile(data).then(res => {
          if (res.code === 0) {
            this.ownedEditions = res.ownedEditions;
            this.userInfo = res.userInfo;
            if (this.userInfo.avatarInfo && this.userInfo.avatarInfo.length) {
              this.avatarUrl = res.userInfo.avatarInfo?.[0].resUrl;
            } else {
              this.avatarUrl = '';
            }
          } else {
            this.$router.push('/network');
          }
        });
      }
      if (item?.userType === 2) {
        this.$router.push(`/mylist/artist/${item.mbrNo}`);
      }
    },
    loadMore() {
      /*
      this.getCallNftList().then(nftList => {
        const data = {
          mbrNo: this.mbrNo,
          nftList: nftList,
        };
      });
      */
    },
    goMarketPlaceItemList() {
      this.$router.push('/marketplace');
    },
    follow() {
      if (this.accessToken) {
        const data = {
          followingMbrNo: this.mbrNo || this.$route.params.mbrNo,
          followingYn: this.userInfo.followYn === 'N' ? 'Y' : 'N',
        };
        this.api.setUserFollowing(data).then(res => {
          if (res.code === 0) {
            this.getUserProfile();
          } else {
            this.$router.push('/network');
          }
        });
      } else {
        this.$root.$emit('openAlert', this.loginAlert);
      }
    },
    openModal(ref, boolean) {
      this.$refs[ref].open(boolean);
    },
    onSetCloseFocus(idx) {
      let foucsEl;
      if (idx === 1) {
        foucsEl = this.$refs.following;
      } else {
        foucsEl = this.$refs.followers;
      }
      setTimeout(() => {
        foucsEl.focus();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
