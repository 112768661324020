<template>
  <div class="banner_wrap">
    <swiper
      ref="mvSwiper"
      class="banner_list"
      :class="addClass"
      :options="slideOpt"
    >
      <swiper-slide
        class="banner_node"
        v-for="(banner, i) in itemData"
        :key="'banner' + i"
      >
        <!-- 광고배너 적용 -->
        <button
          v-if="banner.type === 'banner'"
          class="banner_item"
          :class="{ is_mobile: $isMobile() }"
          @click="accessToBanner(banner)"
          :title="banner.eventTitle"
          style="width: 100%; text-align: left"
          :style="`background-image: url(${banner.resourceInfo.resUrl})`"
        >
          <div class="set_inner">
            <div class="txt_area">
              <!-- NOTE: leave for history -->
              <!-- <h2 class="ttl">How to use LG Art Lab</h2>
              <p class="desc">A step-by-step guide on using LG Art Lab</p> -->
              <h2 v-if="banner.eventTitle" class="ttl">
                {{ banner.eventTitle }}
              </h2>
              <p v-if="banner.desc" class="desc">{{ banner.desc }}</p>
            </div>
          </div>
        </button>
        <button
          v-else-if="banner.mimeType === 'image/jpeg'"
          @click="goCuration(banner.dropsId)"
          class="banner_item"
          style="width: 100%; text-align: left"
          :title="banner.title"
          :style="`background-image: url(${checkImageType(banner.imageInfos)})`"
        >
          <div class="set_inner">
            <CountDown
              v-if="banner.type == 'live'"
              :date-time="banner.dropsEndDate"
            />
            <div v-else-if="banner.type == 'upcoming'" class="txt_area">
              <p>Upcoming Drops</p>
              <h4 class="upcomingDate">
                {{ setUpcomingDateStr(banner.dropsId, banner.dropsStartDate) }}
              </h4>
            </div>
            <div v-else class="txt_area">
              <h4 class="upcomingDate">Ended Drops</h4>
            </div>
            <!--NOTE: expose data changed by planer, 2022-08-04 -->
            <!-- <div class="txt_area">
              <h2 class="ttl">{{ banner.title }}</h2>
              <p class="desc">{{ banner.artworkDescription }}</p>
              <p>
                {{
                  banner.totalEdition > 1
                    ? `${banner.totalEdition} Editions`
                    : `${banner.totalEdition} Edition`
                }}
              </p>
            </div> -->
            <div class="txt_area">
              <h2 class="ttl">{{ banner.artistName }}</h2>
              <p class="desc">{{ banner.title }}</p>
            </div>
          </div>
        </button>
        <button
          v-else
          @click="goCuration(banner.dropsId)"
          :title="banner.title"
          style="width: 100%; text-align: left"
          class="banner_item"
          :style="`background-image: url(${banner.videoThumbInfos[0].resUrl})`"
        >
          <div class="set_inner">
            <CountDown
              v-if="banner.type == 'live'"
              :date-time="banner.dropsEndDate"
            />
            <!--NOTE: expose data changed by planer, 2022-08-04 -->
            <!-- <div class="txt_area">
              <h2 class="ttl">{{ banner.title }}</h2>
              <p class="desc">{{ banner.artworkDescription }}</p>
              <p>
                {{
                  banner.totalEdition > 1
                    ? `${banner.totalEdition} Editions`
                    : `${banner.totalEdition} Edition`
                }}
              </p>
            </div> -->
            <div class="txt_area">
              <h2 class="ttl">{{ banner.artistName }}</h2>
              <p class="desc">{{ banner.title }}</p>
            </div>
          </div>
        </button>
      </swiper-slide>
    </swiper>
    <div class="set_inner">
      <div class="controller_group">
        <div class="control_area pagination_area type_dot"></div>
        <button class="btn type_ic play" v-if="isPlay" @click="playPause">
          <IcPauseS />
        </button>
        <button class="btn type_ic play" v-if="!isPlay" @click="playPause">
          <IcPlayS />
        </button>
        <div class="control_area slide_nav_area">
          <button class="btn type_ic prev">
            <ArwPrevS />
          </button>
          <button class="btn type_ic next">
            <ArwNextS />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArwPrevS from '@/components/ic/arwPrevS';
import ArwNextS from '@/components/ic/arwNextS';
import IcPauseS from '@/components/ic/IcPauseS';
import IcPlayS from '@/components/ic/IcPlayS';
import CountDown from '@/components/drops/CountDown';

export default {
  name: 'BannerSlide',
  components: {
    CountDown,
    IcPauseS,
    IcPlayS,
    ArwNextS,
    ArwPrevS,
  },
  props: {
    slideOpt: {
      type: Object,
      default: null,
    },
    itemData: {
      type: Array,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPlay: true,
    };
  },
  computed: {
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    if (this.itemData) {
      // console.log(this.itemData);
      this.itemData.map(item => {
        item.dropsStartTimeOnly = this.$dayJs(item.dropsStartDate).format(
          'hh:mm a',
        );
        this.$set(this.itemData, 'dropsStartTimeOnly', item.dropsStartTimeOnly);
      });
    }
  },
  methods: {
    playPause() {
      this.isPlay
        ? this.$refs.mvSwiper.$swiper.autoplay.stop()
        : this.$refs.mvSwiper.$swiper.autoplay.start();

      this.isPlay = !this.isPlay;
    },
    accessToBanner(banner) {
      if (banner.expsType != 30 && !this.openService) return false;

      if (banner.expsType == 10) {
        if (banner.linkTypes == 10) {
          this.$router.push({
            path: banner.targetLink,
          });
        } else {
          window.open(banner.targetLink, '_self');
        }
      } else if (banner.expsType == 20) {
        let _targetLink =
          banner.linkType == 10
            ? process.env.VUE_APP_SERVICE_HOST.replace(/\/$/, '') +
              banner.targetLink
            : banner.targetLink;
        window.open(_targetLink, '_blank');
      } else if (banner.expsType == 30) {
        if (banner.targetLink == 'Tutorial') {
          this.$root.$emit('openTutorial');
        } else {
          /*
          var options =
            'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no';
          window.open(banner.targetLink, 'popup', options);
          */
          if (this.$isMobile()) {
            this.$root.$emit('bannerMobileModal');
          } else {
            this.$root.$emit('bannerModal');
          }
        }
      }
    },
    // goLocation(link) {
    //   if (!link) return false;

    //   if (link === 'Tutorial') {
    //     this.$root.$emit('openTutorial');
    //     return false;
    //   }

    //   if (!this.openService) return false;

    //   this.$router.push({
    //     path: link,
    //   });
    // },
    goCuration(dropsId) {
      if (!this.openService) return false;

      this.$router.push({
        name: 'drops-curation',
        query: { dropsId: dropsId },
      });
    },
    checkImageType(bannerInfos) {
      //orgin imageInfos[0] 사용하던걸 명확하게 적용할 필요성이 있다 판단하여 코드 리펙토리 진행하라는 지시 적용 by hy lee
      let banner = bannerInfos.find(banner => banner.rsrcTp === '20');
      return banner.resUrl;
    },
  },
};
</script>

<style scoped>
.upcomingDate {
  font-size: 2.3rem;
  margin-bottom: 30px;
}
.est {
  font-size: 1.5rem;
}
.banner_item {
  cursor: pointer;
}
.banner_item.is_mobile:before {
  background: none !important;
}
</style>
